<template>
	<div class="indexBox">
		<div class="loginFromBox">
			<p style="font-size: 14px; line-height: 1.5"><a>账号密码登录</a></p>
			<a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmitLogin">
				<a-form-item v-bind="formItemLayout">
					<a-input
						style="width: 368px; height: 40px"
						placeholder="邮箱账号"
						v-decorator="[
							'email',
							{
								rules: [
									{
										type: 'email',
										message: '输入你的邮箱'
									},
									{
										required: true,
										message: '输入你的邮箱'
									}
								]
							}
						]"
					/>
				</a-form-item>
				<a-form-item v-bind="formItemLayout" has-feedback>
					<a-input
						style="width: 368px; height: 40px"
						placeholder="密码"
						v-decorator="[
							'password',
							{
								rules: [
									{
										required: true,
										message: '输入你的密码'
									}
								]
							}
						]"
						type="password"
					/>
				</a-form-item>
				<div class="boxleft">
					<span @click="gotouter"><a>忘记密码</a></span>
					<span @click="gotouters"><a>注册账号</a></span>
				</div>
				<a-form-item class="centerBox" style="width: 370px; margin: 0">
					<a-button type="primary" html-type="submit" class="login-form-button" style="width: 370px">确定</a-button>
				</a-form-item>
			</a-form>
		</div>
		<div>
			<router-view />
		</div>
	</div>
</template>
<script>
import { message } from 'ant-design-vue'
import api from '../../api/api'
export default {
	beforeCreate() {
		this.form = this.$form.createForm(this, { name: 'normal_login' })
		this.form = this.$form.createForm(this, { name: 'register' })
	},
	data() {
		return {
			zcState: false,
			des: '',
			comeman: 60,
			getcodes: false,
			confirmDirty: false,
			autoCompleteResult: [],
			formItemLayout: {
				labelCol: {
					xs: { span: 24 },
					sm: { span: 8 }
				},
				wrapperCol: {
					xs: { span: 24 },
					sm: { span: 16 }
				}
			},
			tailFormItemLayout: {
				wrapperCol: {
					xs: {
						span: 24,
						offset: 0
					},
					sm: {
						span: 16,
						offset: 8
					}
				}
			}
		}
	},

	methods: {
		//忘记密码
		gotouter() {
			this.$router.push('/user/resetSend')
		},
		//注册
		gotouters() {
			this.$router.push('/user/register')
		},
		//登录
		handleSubmitLogin(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					//发送接口后跳转页面
					const data = await api.goLogin(values)
					if (data.id) {
						message.success('登录成功')
						this.$router.push('/dashboard')
					} else {
						message.error(`用户名或密码错误`)
					}
				}
			})
		}
		//zhengjai
	}
}
</script>

<style scoped lang="scss">
.indexBox {
	// width: 1200px;
	height: 500px;
	margin: auto;
	// border: 1px solid rgba(121, 121, 121, 1);
	box-sizing: border-box;
	//登录样式
	.loginBox {
		width: 100%;
		height: 100%;
		background: red($color: #000000);
		h1 {
			margin: auto;
			margin-top: 100px;
			text-align: center;
			font-weight: bold;
		}
		.loginFromBox {
			a {
				color: #1890ff;
				font-weight: 500;
				padding: 12px 16px;
				display: inline-block;
				border-bottom: #1890ff 2px solid;
			}
			.boxleft {
				display: flex;
				justify-content: space-between;
				width: 100%;
				height: 50px;
				text-align: right;
				a {
					display: inline;
					padding: 10px;
					border-bottom: 1px solid #37352f;
					font-size: 14px;
					font-variant: tabular-nums;
					border: none;
					color: #37352f;
				}
			}
			margin: auto;
			margin-top: 30px;
			width: 368px;
			height: 350px;
			box-sizing: border-box;
			padding: 50px 0;
			.yueduBOX {
				width: 100%;
				margin-bottom: 20px;
				display: flex;
				justify-content: space-evenly;
				flex-wrap: nowrap;

				div {
					text-align: center;
					flex-grow: 1;
					width: 40%;
					display: inline-block;
				}
			}
			.centerBox {
				display: flex;
				margin-bottom: 10px;
				justify-content: center;
			}
			.a_hre {
				text-align: center;
			}
			p {
				text-align: center;
				font-size: 18px;
				color: black;
			}
			.butonOption {
				width: 120px;
				height: 40px;
				position: relative;
				left: 250px;
				top: -40px;
			}
		}
		.changeTab {
			width: 100%;
			height: 35px;

			display: flex;
			justify-content: space-between;
			span {
				font-size: 18px;
				font-weight: bold;
				display: inline-block;
			}
		}
	}
	//外部样式
	#components-form-demo-normal-login .login-form {
		max-width: 300px;
	}
	#components-form-demo-normal-login .login-form-forgot {
		float: right;
	}
	#components-form-demo-normal-login .login-form-button {
		margin: auto;
		width: 140px;
		height: 45px;
	}
	.a_hre {
		text-align: center;
	}
}
</style>
