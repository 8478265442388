var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"indexBox"},[_c('div',{staticClass:"loginFromBox"},[_vm._m(0),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmitLogin}},[_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{
							rules: [
								{
									type: 'email',
									message: '输入你的邮箱'
								},
								{
									required: true,
									message: '输入你的邮箱'
								}
							]
						}
					]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\ttype: 'email',\n\t\t\t\t\t\t\t\t\tmessage: '输入你的邮箱'\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '输入你的邮箱'\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t]\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],staticStyle:{"width":"368px","height":"40px"},attrs:{"placeholder":"邮箱账号"}})],1),_c('a-form-item',_vm._b({attrs:{"has-feedback":""}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{
							rules: [
								{
									required: true,
									message: '输入你的密码'
								}
							]
						}
					]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: '输入你的密码'\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t]\n\t\t\t\t\t\t}\n\t\t\t\t\t]"}],staticStyle:{"width":"368px","height":"40px"},attrs:{"placeholder":"密码","type":"password"}})],1),_c('div',{staticClass:"boxleft"},[_c('span',{on:{"click":_vm.gotouter}},[_c('a',[_vm._v("忘记密码")])]),_c('span',{on:{"click":_vm.gotouters}},[_c('a',[_vm._v("注册账号")])])]),_c('a-form-item',{staticClass:"centerBox",staticStyle:{"width":"370px","margin":"0"}},[_c('a-button',{staticClass:"login-form-button",staticStyle:{"width":"370px"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v("确定")])],1)],1)],1),_c('div',[_c('router-view')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticStyle:{"font-size":"14px","line-height":"1.5"}},[_c('a',[_vm._v("账号密码登录")])])}]

export { render, staticRenderFns }